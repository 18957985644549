import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { CreateStripeAccountRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useConnectStripeAccount = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: create, isLoading: isCreateLoading } = useMutation(
    ["revenues/createStripeAccount"],
    (data: CreateStripeAccountRequest) => api.createStripeAccount(data),
  );

  const { mutateAsync: connect, isLoading: isConnectLoading } = useMutation(
    ["revenues/connectStripeAccount"],
    () => api.connectStripeAccount(),
  );

  return {
    create,
    connect,
    isCreateLoading,
    isConnectLoading,
    isMutating: isCreateLoading || isConnectLoading,
  };
};
