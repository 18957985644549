import { formatPayoutStatus } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { PayoutStatus } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  status: PayoutStatus;
};

export const PayoutStatusBadge: BFC<Props> = ({
  status,
  className,
}) => {
  const classes = twClassNames(
    {
      "bg-gray-100": status === PayoutStatus.Requested || status === PayoutStatus.InProgress,
      "bg-green-100 text-green-600": status === PayoutStatus.Completed,
      "bg-gray-100 text-gray-400": status === PayoutStatus.Canceled,
      "bg-red-100 text-red-600": status === PayoutStatus.Failed || status === PayoutStatus.Rejected,
    },
    className,
  );

  return (
    <span className={twClassNames("px-2 py-0.5 inline-block text-xs rounded-full", classes)}>
      {formatPayoutStatus(status)}
    </span>
  );
};
