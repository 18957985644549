"use client";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

import { CFC } from "shared/types";

import { AuthedRoute, useAuthContext } from "~/features/auth";

type Props = {
  fallbackTo: string;
};

export const StripeConnectedRoute: CFC<Props> = ({
  fallbackTo,
  children,
}) => {
  return (
    <AuthedRoute>
      <StripeConnectedRouteInternal fallbackTo={fallbackTo}>
        {children}
      </StripeConnectedRouteInternal>
    </AuthedRoute>
  );
};

const StripeConnectedRouteInternal: CFC<Props> = ({
  fallbackTo,
  children,
}) => {
  const { user } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    if (!user.stripeAccountVerified) {
      router.replace(fallbackTo);
    }
  }, [user.stripeAccountVerified]);

  if (!user.stripeAccountVerified) {
    return <></>;
  }

  return <>{children}</>;
};
