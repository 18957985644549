import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Balance } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useBalances = () => {
  const { accessToken, user } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [balances, setBalances] = useState<Balance[]>([]);

  const { isLoading } = useQuery(
    ["revenues/getBalances"],
    () => api.getUserBalances(),
    {
      enabled: user.stripeAccountVerified,
      onSuccess: ({ data: { balances } }) => {
        setBalances(balances.map((balance) => new Balance(balance)));
      },
    },
  );

  return {
    balances,
    isLoading,
  };
};
