import classNames from "classnames";

import { formatDateTime, formatMoney } from "shared/helpers";
import { Payout } from "shared/models";
import { BFC } from "shared/types";

import { PayoutStatusBadge } from "../PayoutStatusBadge";

type Props = {
  payout: Payout;
};

export const PayoutListItem: BFC<Props> = ({
  payout,
  className,
}) => {
  return (
    <div className={classNames("p-3 flex flex-col gap-1", className)}>
      <div className="text-black-500">{formatDateTime(payout.requestedAt)}</div>
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">{formatMoney(payout.amount)}</div>
        <PayoutStatusBadge status={payout.status} />
      </div>
      {(payout.failedMessage || payout.rejectedMessage) && (
        <div className="text-sm text-red-500">{payout.failedMessage || payout.rejectedMessage}</div>
      )}
    </div>
  );
};
