import classNames from "classnames";
import { useMemo } from "react";

import { formatDate, formatMoney } from "shared/helpers";
import { Revenue } from "shared/models";
import { BFC } from "shared/types";

import { RevenueStatusBadge } from "../RevenueStatusBadge";

type Props = {
  revenue: Revenue;
};

export const RevenueListItem: BFC<Props> = ({
  revenue,
  className,
}) => {
  const support = useMemo(() => revenue.getSupport(), [revenue]);
  const comment = useMemo(() => support?.getComment(), [support]);

  return (
    <div className={classNames("p-3 flex flex-col gap-1", className)}>
      <div className="text-black-500">{formatDate(revenue.receivedAt, "yyyy年MM月dd日")}</div>
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">{formatMoney(revenue.netAmount)}</div>
        <RevenueStatusBadge status={revenue.status} />
      </div>
      {revenue.description && (
        <div className="text-black-400 text-sm">{revenue.description}</div>
      )}
      {comment && (
        <div className="text-black-500 mt-1 line-clamp-1 rounded bg-gray-100 p-2 text-sm">{comment.body}</div>
      )}
    </div>
  );
};
