"use client";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AiTwotoneMoneyCollect } from "react-icons/ai";
import { FcComboChart, FcLibrary } from "react-icons/fc";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";

import { Button, LoadingOverlay } from "shared/components";
import { formatDate, formatPriceWithCurrency, newZonedDate } from "shared/helpers";
import { MoneyCurrency } from "shared/models";

import { routes } from "~/constants";

import { RevenueListItem, RevenuesChart } from "../../components";
import { useBalances, useRevenues, UseRevenuesParams } from "../../hooks";

type BalanceType = {
  amount: number;
  pending_amount: number;
  currency: MoneyCurrency;
};

export const RevenuesPage = () => {
  const router = useRouter();
  const [date, setDate] = useState<Date>(newZonedDate());
  const [params, setParams] = useState<UseRevenuesParams>({});
  const { balances: balancesData, isLoading: isBalancesLoading } = useBalances();
  const { revenues, startDate, endDate, isFetching: isRevenuesLoading } = useRevenues(params);

  const dateYYYYMM = useMemo(() => formatDate(date, "yyyy-MM"), [date]);
  const dateString = useMemo(() => formatDate(date, "yyyy年MM月"), [date]);
  const hasNextMonth = useMemo(() => Number(formatDate(date, "yyyyMM")) < Number(formatDate(new Date(), "yyyyMM")), [date]);

  const revenueBalances: BalanceType[] = useMemo(() => {
    if (revenues.length === 0) {
      return [
        { amount: 0, pending_amount: 0, currency: MoneyCurrency.Jpy },
      ];
    }

    return revenues.reduce((acc, revenue) => {
      const currency = revenue.amount.currency;
      const balance = acc.find((b) => b.currency === currency);
      if (balance) {
        balance.amount += revenue.netAmount.amount;
      } else {
        acc.push({
          amount: revenue.netAmount.amount,
          pending_amount: 0,
          currency,
        });
      }
      return acc;
    }, [] as BalanceType[]);
  }, [revenues]);

  useEffect(() => {
    setParams({ date: dateYYYYMM });
  }, [dateYYYYMM]);

  const balances = useMemo(() => balancesData.reduce((acc, balance) => {
    const index = acc.findIndex((item) => item.currency === balance.amount.currency);
    if (index === -1) {
      acc.push({
        amount: balance.isAvailable() ? balance.amount.amount : 0,
        pending_amount: balance.isPending() ? balance.amount.amount : 0,
        currency: balance.amount.currency,
      });
    } else {
      acc[index].amount += balance.isAvailable() ? balance.amount.amount : 0;
      acc[index].pending_amount += balance.isPending() ? balance.amount.amount : 0;
    }
    return acc;
  }, [] as BalanceType[]), [balancesData]);

  const hasPendingBalance = useMemo(() => balances.some((balance) => balance.pending_amount > 0), [balances]);

  const onPrevMonthClick = useCallback(() => {
    setDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
  }, [date]);

  const onNextMonthClick = useCallback(() => {
    if (!hasNextMonth) return;
    setDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  }, [date, hasNextMonth]);

  const onPayoutClickFactory = useCallback((currency: MoneyCurrency) => () => {
    router.push(routes.MYPAGE_PAYOUTS(currency));
  }, []);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <AiTwotoneMoneyCollect size={24} />
          収益状況
        </h1>
        <div className="text-black-400 text-sm">
          収益状況の確認や、収益の引き出し依頼を行うことができます
        </div>
      </div>

      <div className="flex flex-col gap-3 border-y bg-white p-4">
        <h2 className="flex items-center justify-center gap-1 text-center text-lg font-bold">
          <FcLibrary size={24} />
          収益残高
        </h2>
        <LoadingOverlay loading={isBalancesLoading}>
          <div className={classNames("flex flex-col gap-1 divide-y")}>
            {balances.map((balance) => (
              <div key={balance.currency} className="flex justify-between">
                <div className="flex items-center gap-1">
                  <div className="text-lg font-bold">
                    {formatPriceWithCurrency(balance.amount, balance.currency)}
                  </div>
                  {balance.pending_amount > 0 && (
                    <div className="flex items-center">
                      +{formatPriceWithCurrency(balance.pending_amount, balance.currency)}
                      <span className="text-black-400 text-sm">(保留中)</span>
                    </div>
                  )}
                </div>
                <div>
                  <Button small onClick={onPayoutClickFactory(balance.currency)}>出金する</Button>
                </div>
              </div>
            ))}
          </div>
          {hasPendingBalance && (
            <div className="rounded border bg-gray-100 p-3 text-sm text-gray-500">
              「保留中」の金額は、現在Stripeによる手続き中の金額のため、引き出し依頼を行うことができません。
            </div>
          )}
        </LoadingOverlay>
      </div>

      <div className="flex flex-col gap-3 border-y bg-white p-4">
        <div className="flex justify-between gap-3">
          <h2 className="flex flex-1 gap-1 font-bold">
            <FcComboChart size={20} />{dateString}の収益
          </h2>
          <div className="flex gap-1">
            <button onClick={onPrevMonthClick}><HiOutlineChevronLeft size={20} /></button>
            <button onClick={onNextMonthClick} disabled={!hasNextMonth}>
              <HiOutlineChevronRight size={20} className={classNames({ "text-gray-400": !hasNextMonth })} />
            </button>
          </div>
        </div>
        <LoadingOverlay loading={isRevenuesLoading}>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-1 divide-y divide-dashed rounded p-3">
              {revenueBalances.map((balance) => (
                <div key={balance.currency}>
                  <div className="text-center">
                    <div className="text-lg font-bold">
                      {formatPriceWithCurrency(balance.amount, balance.currency)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <RevenuesChart
              revenues={revenues}
              startDate={startDate}
              endDate={endDate}
              className="h-48 w-full"
            />
            <div className="rounded border">
              <h3 className="border-b bg-gray-100 p-3 font-bold text-gray-500">収益一覧</h3>
              {revenues.length === 0 ? (
                <div className="p-3 text-center text-gray-500">収益がありません</div>
              ) : (
                <div className="divide-y">
                  {revenues.map((revenue) => (
                    <RevenueListItem key={revenue.id} revenue={revenue} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};
