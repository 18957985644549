import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { Revenue } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { GetUserRevenuesRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export type UseRevenuesParams = {
  date?: string;
  status?: GetUserRevenuesRequest["status"];
};

export const useRevenues = (params: UseRevenuesParams = {}) => {
  const { accessToken, user } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [revenues, setRevenues] = useState<Revenue[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { fetchNextPage, hasNextPage, isLoading, isFetching } = useInfiniteQuery(
    ["revenues/revenues", params],
    ({ pageParam }) => api.getUserRevenues(pageParam, params.date, params.status),
    {
      enabled: user.stripeAccountVerified,
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setRevenues(pages.flatMap(({ data: { revenues } }) => revenues.map((revenue) => new Revenue(revenue))));
      },
    },
  );

  useEffect(() => {
    if (params.date && hasNextPage) {
      fetchNextPage();
    }
  }, [params?.date, hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (!params.date) {
      const firstRevenue = revenues[0];
      const lastRevenue = revenues[revenues.length - 1];
      if (firstRevenue) {
        setEndDate(new Date(firstRevenue.receivedAt));
      }
      if (lastRevenue) {
        setStartDate(new Date(lastRevenue.receivedAt));
      }
    } else {
      if (params.date.length === 7) {
        const [year, month] = params.date.split("-");
        setStartDate(new Date(Number(year), Number(month) - 1, 1));
        setEndDate(new Date(Number(year), Number(month), 0));
      } else if (params.date.length === 4) {
        const year = params.date;
        setStartDate(new Date(Number(year), 0, 1));
        setEndDate(new Date(Number(year), 11, 31));
      }
    }
  }, [params?.date, revenues]);

  return {
    revenues,
    startDate,
    endDate,
    isLoading,
    isFetching,
  };
};
