import {
  CategoryScale,
  BarElement,
  BarController,
  ChartData,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  Chart as ChartJS,
} from "chart.js";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Chart } from "react-chartjs-2";

import { formatDate } from "shared/helpers";
import { Revenue } from "shared/models";
import { BFC } from "shared/types";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

type Props = {
  revenues: Revenue[];
  startDate: Date;
  endDate: Date;
};

type DataType = {
  x: string;
  daily: number;
  total: number;
};

export const RevenuesChart: BFC<Props> = ({
  revenues,
  startDate,
  endDate,
  className,
}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [total, setTotal] = useState(0);

  const data: ChartData<"bar" | "line", DataType[]> = useMemo(() => {
    const labels: string[] = [];
    const values: { x: string; daily: number; total: number }[] = [];
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const x = formatDate(date, "dd");

      labels.push(x);
      if (date <= currentDate) {
        const dailyRevenue = revenues.reduce((acc, revenue) => {
          if (revenue.isReceivedDate(date)) {
            acc += revenue.netAmount.amount;
          }
          return acc;
        }, 0);
        values.push({
          x,
          daily: dailyRevenue,
          total: values.length ? values[values.length - 1].total + dailyRevenue : dailyRevenue,
        });
      }
    }

    return {
      labels,
      datasets: [
        {
          type: "bar",
          backgroundColor: "#0ea5e9",
          data: values,
          label: "日別収益",
          parsing: {
            xAxisKey: "x",
            yAxisKey: "daily",
          },
        },
        {
          type: "line",
          backgroundColor: "#22c55e",
          data: values,
          label: "累計収益",
          parsing: {
            xAxisKey: "x",
            yAxisKey: "total",
          },
        },
      ],
    };
  }, [revenues, startDate, endDate, currentDate]);

  useEffect(() => {
    setCurrentDate(new Date());
  }, []);

  useEffect(() => {
    setTotal(revenues.reduce((acc, revenue) => acc + revenue.amount.amount, 0));
  }, [revenues]);

  return (
    <div className={classNames(className)}>
      <Chart
        type="bar"
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
              align: "end",
              labels: {
                boxWidth: 10,
                boxHeight: 10,
                pointStyle: "circle",
                usePointStyle: true,
              },
            },
          },
          scales: {
            y: {
              min: 0,
              max: total > 1000 ? total * 1.5 : 1000,
              grid: {
                display: false,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
};
