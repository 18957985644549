import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { RequestPayoutRequest } from "~/../../packages/shared/services/api/web";

import { Payout } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const usePayouts = () => {
  const { accessToken, user } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [payouts, setPayouts] = useState<Payout[]>([]);

  const { fetchNextPage, hasNextPage, refetch, isLoading, isFetching } = useInfiniteQuery(
    ["revenues/payouts"],
    ({ pageParam }) => api.getPayouts(pageParam),
    {
      enabled: user.stripeAccountVerified,
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setPayouts(pages.flatMap(({ data: { payouts } }) => payouts.map((payout) => new Payout(payout))));
      },
    },
  );

  const { mutateAsync: requestPayout, isLoading: isPayoutMutating } = useMutation(
    ["revenues/payout"],
    (params: RequestPayoutRequest) => api.requestPayout(params),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    requestPayout,
    payouts,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isPayoutMutating,
    isMutating: isPayoutMutating,
  };
};
