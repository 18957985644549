import classNames from "classnames";

import { formatRevenueStatus } from "shared/helpers";
import { RevenueStatus } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  status: RevenueStatus;
};

export const RevenueStatusBadge: BFC<Props> = ({
  status,
  className,
}) => {
  const classes = classNames(
    {
      "bg-green-100 text-green-600": status === RevenueStatus.Received,
      "bg-gray-100 text-gray-600": status === RevenueStatus.Accepted,
      "bg-red-100 text-red-600": status === RevenueStatus.Expired,
    },
    className,
  );

  return (
    <span className={classNames("px-2 py-0.5 inline-block text-xs rounded-full", classes)}>
      {formatRevenueStatus(status)}
    </span>
  );
};
